import { motion, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useParallax, useInversedParallax } from "../helper";
import { Code, Terminal, X } from "react-feather";
import FeaturedProject from "../src/components/FeaturedProject";
import { BreathingVariants } from "../motion.variants";
import Link from "next/link";

const Home = (p) => {
  const mainRef = useRef(null);
  const experienceRef = useRef(null);
  const textRef = useRef(null);
  const whyMeRef = useRef(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [selectedFeaturedProject, setSelectedFeaturedProject] = useState("");
  const featuredProjects = [
    {
      mainImage: "/img/highlighted_projects/B2C_Moladin.png",
      innerImage: "/img/highlighted_projects/bg/moladin.svg",
      timeStamp: "Dec 2021 - Feb 2023",
      projectName: "Moladin",
      description: "Yes. I'm one of many moladians you saw out there. We make selling/buying cars easier, safer, and profitable! My responsibility is mainly on CRM side of Moladin. But I sometimes handle the B2C side as well.",
      engines: ["React", "SCSS"],
      bgClass: "bg-[#3d94be]"
    },
    {
      mainImage: "/img/highlighted_projects/BitX.png",
      innerImage: "/img/highlighted_projects/bg/bitx.svg",
      timeStamp: "Nov 2022 - Present",
      projectName: "BitX",
      description: "I started this project with my team in Tangerang. Our goal is to make clients satisfied with our services starting from serverless web app, remaking their products, and even as a consultant we give our best services as possible!",
      engines: ["Next", "SCSS", "GraphQL"],
      bgClass: "bg-[#536fa6]"
    },
    {
      mainImage: "/img/highlighted_projects/Agatha.png",
      innerImage: "",
      timeStamp: "Nov 2020 - Feb 2021",
      projectName: "Agatha",
      description: "This freelance project was one of the best project I've ever worked. We provide detailed search engine for USA Citizens as detail as their location, VIN, and even their social media! Unfortunately this project has come to its end.",
      engines: ["Vue", "SASS"],
      bgClass: "bg-[#9469d1]"
    },
    {
      mainImage: "/img/highlighted_projects/Midas.png",
      innerImage: "",
      timeStamp: "Sep 2022 - Present",
      projectName: "Untitled.",
      description: "Our team is anonymous, we create an engine that integrated with online casinos and slots. It is scalable, manageable, and reusable. We even implement it to be able to bet using both cryptocurrency and FIAT. It is also SEO friendly and that's one the challenge in this project.",
      engines: ["Next", "SCSS", "AWS"],
      bgClass: "bg-[#384b57]"
    },

  ];

  const [textOpacityVal, setTextOpacityVal] = useState("90deg");
  const { scrollYProgress: textYProgress } = useScroll({ target: textRef });
  const { scrollYProgress: whyMeYProgress } = useScroll({ target: whyMeRef });

  const whyMeYXPos1 = useParallax(whyMeYProgress, "70%");
  const whyMeYXPos2 = useInversedParallax(whyMeYProgress, "70%");

  useEffect(() => {
    textYProgress.onChange(setTextOpacityVal);
  }, []);

  useEffect(() => {
    if (loginLoading) setTimeout(() => {
      setLoginLoading(false);
    }, 1500);
  }, [loginLoading]);

  useEffect(() => {
    if (window) {
      // easter egg
      window.getGitHub = () => {
        console.log("Ouch you found me!");
        setTimeout(() => {
          window.open("https://github.com/shidoitsuka", "_blank");
        }, 1000);
      };

      p.setGlobalLoading(false);
      VANTA.DOTS({
        el: "#three-bg",
        mouseControls: true,
        touchControls: true,
        gyroControls: true,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        color: 0x414E79,
        color2: 0x374151,
        backgroundColor: 0xF3F4F6,
        showLines: false
      });

      const startDrag = () => {
        window.onmousemove = (e) => {
          percentage = (e.clientX / maxMousePos) * 100 + 7.1;
          if (percentage >= maxPercentage || percentage <= 20) return;
          responsiveEl.style.width = `${percentage}%`;
          resizeEl.style.marginRight = `${maxPercentage - percentage}%`;
        }
        window.onmouseup = () => {
          window.onmousemove = () => {}
        }
      }

      const startMouseMove = (ev) => {
        const mainTextEl = document.getElementById("mainText");
        const subTextEl = document.getElementById("subText");
        const perXPoint = window.innerWidth / (15 - 9);
        const perYPoint = window.innerHeight / (11 - 7);
        eyesEl.setAttribute("cx", ev.clientX / perXPoint + 9);
        eyesEl.setAttribute("cy", ev.clientY / perYPoint + 7);
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight / 2;
        mainTextEl.style.transform = `translate(${((ev.clientX - centerX) / centerX) * 3}%, ${((ev.clientY - centerY) / centerY) * 3}%)`;
        subTextEl.style.transform = `translate(${((centerX - ev.clientX) / centerX) * 8}px, ${((centerY - ev.clientY) / centerY) * 8}%)`;
      }
      
      const responsiveEl = document.getElementById("responsive-element");
      const resizeEl = document.getElementById("resize-responsive");
      const eyesEl = document.getElementById("lens");
      const maxMousePos = window.innerWidth / 2;
      const maxPercentage = 100.3;
      let percentage = 0;
      window.addEventListener("mousemove", startMouseMove);
      resizeEl.addEventListener("mousedown", startDrag);
      return () => {
        resizeEl.removeEventListener("mouseup", startDrag);
        window.removeEventListener("mousemove", startMouseMove);
      }
    }
  }, []);

  // useEffect(() => {
  //   const el = document.getElementById("cursor");
  //   document.body.onpointermove = event => {
  //     const { clientX, clientY } = event;
  //     el.style.left = `${clientX}px`;
  //     el.style.top = `${clientY}px`;
  //   };
  // }, []);

  p = {
    ...p,
    selectedFeaturedProject,
    setSelectedFeaturedProject
  };

  return (
    <main className="index">
      {/* <div className="z-10" id="cursor"></div> */}
      <section className="main">
        <div id="three-bg" />
        <div className="content">
          <div className="text-container">
            <h1 id="mainText" ref={mainRef}>Brian Harianja</h1>
            <div id="subText">
              <h2>WebGL<span className="stretch mx-2">OO</span>Photography<span className="stretch mx-2">OO</span>UI/UX</h2>
              <h2>Responsive Web Design<span className="stretch mx-2">OO</span>Cinematic</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="experience">
        <div className="flex overflow-scroll no-scrollbar rounded-t-lg relative z-10">
          <motion.img className="experience-img" src="/img/projects/Agatha.png" />
          <motion.img className="experience-img" src="/img/projects/Bowtie.png" />
          <motion.img className="experience-img" src="/img/projects/B2C_Moladin.png" />
          <motion.img className="experience-img" src="/img/projects/James.png" />
        </div>
        <div className="h-80">
        </div>
        <div className="flex overflow-scroll no-scrollbar rounded-b-lg relative z-10">
          <motion.img className="experience-img" src="/img/projects/Allele.png" />
          <motion.img className="experience-img" src="/img/projects/BitX.png" />
          <motion.img className="experience-img" src="/img/projects/CRM_Moladin.png" />
          <motion.img className="experience-img" src="/img/projects/Midas.png" />
        </div>
        <div className="absolute h-full top-0 flex" ref={experienceRef}>
          <h1 className="rounded-lg text-4xl font-bold my-auto md:text-6xl z-10">I make crazy shit,<br />and that <span className="stretch text-primary">craazy</span> shit usually loved.</h1>
        </div>
      </section>

      <section className="w-full h-[200vh] text-white bg-black first-skills snap-y snap-mandatory" ref={textRef}>
        <div className="h-screen w-full sticky top-0">
          <div className="container h-full grid grid-cols-1 md:grid-cols-5">
            <div className="relative inset-top overflow-hidden md:inset-left">
              {textOpacityVal < 0.33 ?
              <motion.img animate={{scale: textOpacityVal + 1 * 1}} className="md:absolute h-full object-cover w-full" src="https://picsum.photos/1300/1100" alt="" />
              : textOpacityVal < 0.66 ?
              <motion.img animate={{scale: textOpacityVal + 1 * 1}} className="md:absolute h-full object-cover w-full" src="https://picsum.photos/1300/1101" alt="" />
              :
              <motion.img animate={{scale: textOpacityVal + 1 * 1}} className="md:absolute h-full object-cover w-full" src="https://picsum.photos/1300/1102" alt="" />
              }
            </div>
            <div className="font-bold text-xl text-center col-span-3 my-auto md:text-4xl">
              <motion.p className="z-20 relative duration-300" style={{ opacity: textOpacityVal < 0.33 ? 1 : 0.5 }}>Modern Technology</motion.p>
              <motion.p className="z-20 relative duration-300" style={{ opacity: (textOpacityVal > 0.33 && textOpacityVal < 0.66) ? 1 : 0.5 }}>Fast Development</motion.p>
              <motion.p className="z-20 relative duration-300" style={{ opacity: textOpacityVal > 0.66 ? 1 : 0.5 }}>Modern Frameworks</motion.p>
            </div>
            <div className="relative inset-bottom overflow-hidden md:inset-right">
              {textOpacityVal < 0.33 ?
              <motion.img animate={{scale: textOpacityVal + 1 * 1}} className="md:absolute h-full object-cover w-full" src="https://picsum.photos/1300/1100" alt="" />
              : textOpacityVal < 0.66 ?
              <motion.img animate={{scale: textOpacityVal + 1 * 1}} className="md:absolute h-full object-cover w-full" src="https://picsum.photos/1300/1101" alt="" />
              :
              <motion.img animate={{scale: textOpacityVal + 1 * 1}} className="md:absolute h-full object-cover w-full" src="https://picsum.photos/1300/1102" alt="" />
              }
            </div>
          </div>
        </div>
      </section>

      {/* <section className="w-full h-screen container my-10">
        <div className="flex h-full gap-3">
          <section className="w-2/4">

            <div className="browser text-gray-300 bg-[#36363a] rounded-lg w-full h-1/2 text-xs">
              <div className="top-bar w-full bg-[#202124] rounded-t-lg px-2 pt-2 flex gap-2">
                <div className="window-buttons flex mt-1 gap-1">
                  <div className="w-2 h-2 bg-[#ed6a5e] rounded-full"></div>
                  <div className="w-2 h-2 bg-[#f5be4f] rounded-full"></div>
                  <div className="w-2 h-2 bg-[#62c554] rounded-full"></div>
                </div>
                <div className="tabs flex">
                  <div className="flex items-center gap-1 bg-[#36363a] px-2 py-1 rounded-t cursor-pointer">
                    <span>tab title</span>
                    <X size={10} />
                  </div>
                  <div className="flex items-center gap-1 border-r border-[#36363a] px-2 py-1 cursor-pointer">
                    <span>tab title</span>
                    <X size={10} />
                  </div>
                  <div className="flex items-center gap-1 border-r border-[#36363a] px-2 py-1 cursor-pointer">
                    <span>tab title</span>
                    <X size={10} />
                  </div>
                </div>
                <div className="flex items-center ml-auto">
                  <ChevronDown size={10} />
                </div>
              </div>
              <div className="address-line flex p-2 gap-2">
                <div className="action-buttons flex items-center gap-1">
                  <ArrowLeft size={10} />
                  <ArrowRight size={10} />
                  <RefreshCw size={10} />
                </div>
                <div className="address-bar w-full">
                  <input className="w-full rounded-full bg-[#202124] px-2" type="text" />
                </div>
                <div className="user-extensions flex items-center">
                  <Menu size={10} />
                </div>
              </div>
              <div className="h-full bg-white rounded-b-lg px-5 py-3">content here</div>
            </div>
            
          </section>
          <section className="">
            test
          </section>
        </div>
      </section> */}
      <section className="w-full highlighted-works relative z-10">

        <div className="container py-10">
          <h1 className="text-4xl font-bold text-center md:text-6xl">highlighted <span className="stretch text-primary text-shadow-inner" text="works">works</span>.</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 py-10">
              {featuredProjects.map((project, index) => (
                <motion.div layout key={index} animate={{ x: [-300, 0] }} transition={{ duration: 0.5 }} style={selectedFeaturedProject === project.projectName && { gridColumn: "1 / -1" }}>
                  <FeaturedProject {...project} {...p}>
                    <motion.p className="text-sm md:text-md">{project.description}</motion.p>
                  </FeaturedProject>
                </motion.div>
              ))}
            </div>
        </div>
        
      </section>

      <motion.section ref={whyMeRef} style={{ rotate: "-8deg", x: -5 }} className="absolute z-10 w-full text-4xl no-scrollbar font-bold text-center overflow-hidden whitespace-nowrap mt-24 md:mt-0 md:text-6xl">
        <motion.h1 style={{ x: whyMeYXPos1 }} className="duration-1000 ease-out text-stroke-primary">why should i hire you? - why should i hire you? - why should i hire you? - why should i hire you? - why should i hire you? - why should i hire you? - why should i hire you?</motion.h1>
        <motion.h1 style={{ x: whyMeYXPos2 }} className="duration-1000 ease-out text-primary py-2">technologies - frameworks - engine - why brian? - how things work - technologies - frameworks - engine - why brian? - how things work - technologies - frameworks - engine - why brian? - how things work - technologies - frameworks - engine - why brian? - how things work</motion.h1>
      </motion.section>

      <section className="w-full technology mt-80 select-none relative z-10">
        <div className="container flex flex-col gap-16 w-full mb-32 md:gap-32 md:flex-row">
          <div className="md:w-1/2">
            <span className="tracking-widest uppercase text-gradient-primary">customizeable</span>
            <h1 className="font-bold text-6xl mb-5">your products is yours.</h1>
            <p className="font-semibold text-gray-400 text-2xl">
              I believe that you are the very owner of your ideas. When you trust me for building your product, the rights of requesting changes, customizing UI, and many more are yours as well. I also will give you advise whenever you are welcome for it. It is your choice after all.
            </p>
          </div>
          <div className="relative overflow-hidden order-first md:order-last md:w-1/2">
            <div className="bg-primary w-full h-96 mx-auto rounded-lg relative">
              <div className="absolute top-0 left-0 rounded-tl-lg bg-gray-100 w-44 h-44"></div>
              <div className="absolute bottom-0 right-0 rounded-br-lg bg-gray-100 w-44 h-44"></div>
              
              <motion.div
                transition={{ repeat: Infinity, repeatType: "reverse", repeatDelay: 2.5, duration: 0.5, background: { delay: 3.5 } }}
                initial={{ y: -300, opacity: 0, background: "#414E79" }}
                animate={{ y: 0, opacity: 1, background: "#b1b1b1" }}
                className="absolute top-0 left-0 rounded-tl-lg bg-red-300 w-44 h-44"
              ></motion.div>
              <motion.div
                transition={{ repeat: Infinity, repeatType: "reverse", repeatDelay: 2.8, duration: 0.3, delay: 0.3, background: { delay: 3.5 } }}
                initial={{ x: 300, opacity: 0, background: "#414E79" }}
                animate={{ x: 0, opacity: 1, background: "#878787" }}
                className="absolute bottom-0 right-0 rounded-br-lg w-44 h-44"
              ></motion.div>
            </div>
          </div>
        </div>

        <div className="container flex flex-col gap-16 w-full mb-8 md:mb-32 md:gap-32 md:flex-row">
          <div className="h-fit md:w-1/2 md:sticky top-10">
            <div className="relative w-full">
              <div id="responsive-element" className="bg-primary w-full h-96 rounded-lg relative" />
              <div className="absolute w-full h-96 flex justify-end items-center -right-4 top-0">
                <div id="resize-responsive" className="h-8 w-2 rounded-full bg-gray-400 cursor-ew-resize" />
              </div>
            </div>
          </div>
          <div className="md:w-1/2">
            <span className="tracking-widest uppercase text-gradient-primary">well-made</span>
            <h1 className="font-bold text-6xl">Handcrafted from every corner.</h1>
            <div className="text-2xl">
              <p className="font-semibold text-gray-400 mt-5 mb-8">
                I don't only make things, I make masterpiece. I put all of my effort in building your ideas into product. Turning your ideas into product means making perfection out of imagination to me.
              </p>
              <div>
                <div className="flex items-center gap-3 mb-3">
                  <div className="relative shrink-0 w-12 h-12 rounded-lg shadow-lg mb-auto">
                    <div className="absolute w-full h-full flex justify-center items-center top-0">
                      <svg
                        className="text-primary w-full feather feather-maximize"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ transform: "translateY(3px)" }}
                      >
                        <motion.path d="M6,1H3C1.9,1,1,1.9,1,3v3m0,8v3c0,1.1,.9,2,2,2h3"></motion.path>
                        <motion.path transition={{ repeat: Infinity, repeatType: "mirror", repeatDelay: 1 }} initial={{ x: "20px" }} animate={{ x: "13px" }} d="M6,6V3c0-1.1-.9-2-2-2H1m0,18h3c1.1,0,2-.9,2-2v-3"></motion.path>
                      </svg>
                    </div>
                  </div>
                  <div>
                    <h1 className="font-bold text-xl my-1">Responsive design? <span className="text-gradient-primary">Made</span>.</h1>
                    <p className="text-sm">Your users aren't always on phone, nor always on their desktop. That's why Resposive Web Design is come in handy.</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 mb-3">
                  <div className="p-3 rounded-lg shadow-lg mb-auto">
                    <svg
                        className="text-primary feather feather-eye"
                        xmlns="http://www.w3.org/2000/svg"
                       width="24"
                       height="24"
                       viewBox="0 0 24 24"
                       fill="none"
                       stroke="currentColor"
                       strokeWidth="2"
                       strokeLinecap="round"
                       strokeLinejoin="round"
                      >
                        <motion.path style={{ originX: "50%", originY: "50%" }} transition={{ duration: 0.5, repeat: Infinity, repeatType: "mirror" }} initial={{ rotateX: "0deg" }} animate={{ rotateX: "70deg" }} id="top-eye" d="M1,9S5,1,12,1s11,8,11,8"></motion.path>
                        <motion.path style={{ originX: "10%", originY: "10%" }} transition={{ duration: 0.5, repeat: Infinity, repeatType: "mirror" }} initial={{ rotateX: "0deg", y: "8px" }} animate={{ rotateX: "70deg" }} id="bottom-eye" d="M23,1s-4,8-11,8S1,1,1,1"></motion.path>
                        <motion.circle transition={{ duration: 0.5, repeat: Infinity, repeatType: "mirror" }} initial={{ opacity: 1 }} animate={{ opacity: 0 }} id="lens" cx="12" cy="9" r="3"></motion.circle>
                      </svg>
                  </div>
                  <div>
                    <h1 className="font-bold text-xl my-1">Great looks? <span className="text-gradient-primary">Gorgeous</span>.</h1>
                    <p className="text-sm">Building product is not always about "it's finished", it also has to be good looking, and I give you service to make your product looks gorgeous.</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 mb-3">
                  <div className="p-3 rounded-lg shadow-lg mb-auto">
                    <svg
                      className="text-primary feather feather-layers"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <motion.polygon transition={{ duration: 1.25, repeat: Infinity, repeatType: "mirror", repeatDelay: 1.25 }} initial={{ y: -30 }} animate={{ y: 0 }} points="12 2 2 7 12 12 22 7 12 2"></motion.polygon>
                      <motion.polyline transition={{ duration: 0.5, repeat: Infinity, repeatType: "mirror", repeatDelay: 2 }} initial={{ y: -50 }} animate={{ y: 0 }} points="2 17 12 22 22 17"></motion.polyline>
                      <motion.polyline transition={{ duration: 1, repeat: Infinity, repeatType: "mirror", repeatDelay: 1.5 }} initial={{ y: -40 }} animate={{ y: 0 }} points="2 12 12 17 22 12"></motion.polyline>
                    </svg>
                  </div>
                  <div>
                    <h1 className="font-bold text-xl my-1">Good performance? <span className="text-gradient-primary">Excellent</span>.</h1>
                    <p className="text-sm">Imagine having a fully functional product but it's slow as hell. User Experience will be drowned to the ground.</p>
                  </div>
                </div>
                <div className="flex items-center gap-3 mb-3">
                  <div className="relative shrink-0 w-12 h-12 rounded-lg shadow-lg mb-auto overflow-x-hidden">
                    <motion.div className="absolute w-full h-full flex justify-center items-center top-0" transition={{ duration: 0.5, repeat: Infinity, repeatType: "mirror", repeatDelay: 1.5 }} initial={{ x: -50 }} animate={{ x: 0 }}>
                      <Code className="text-primary" />
                    </motion.div>
                    <motion.div className="absolute w-full h-full flex justify-center items-center top-0" transition={{ duration: 0.5, repeat: Infinity, repeatType: "mirror", repeatDelay: 1.5, delay: 2 }} initial={{ x: -50 }} animate={{ x: 0 }}>
                      <Terminal className="text-primary" />
                    </motion.div>
                  </div>
                  <div>
                    <h1 className="font-bold text-xl my-1">Clean code? <span className="text-gradient-primary">Maintained</span>.</h1>
                    <p className="text-sm">When you release a product, it doesn't always mean you are the only one who's going to maintain it. Clean code makes it easier for other developers to continue developing your products. It also boost the performance, too!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container flex flex-col gap-16 w-full mb-32 md:gap-32 md:flex-row">
          <div className="md:w-1/2">
            <span className="tracking-widest uppercase text-gradient-primary">modern frameworks</span>
            <h1 className="font-bold text-6xl mb-5">Newest technology for your customer.</h1>
            <p className="font-semibold text-gray-400 text-2xl">
              Old doesn't always mean better. I believe every technology update means better experience both for user and developers. But that doesn't mean newest are better than the older ones. That's why after all, I pick the best just for you so you can rest well and enjoy your final product in perfect condition.
            </p>
          </div>
          <div className="relative order-first w-full h-full flex justify-center items-center md:order-last md:w-1/2">
            <div className="relative w-full flex justify-center items-center h-[25rem] overflow-hidden">

              <motion.div variants={BreathingVariants} transition={{ repeat: Infinity, repeatType: "mirror", duration: 1, repeatDelay: 0.5 }} initial="initial" animate="breathing2" className="absolute">
                <div className="relative">
                  <img className="w-auto h-auto rounded-lg" src="https://picsum.photos/250" />
                  <div className="absolute bottom-0 w-full p-2 bg-glass text-white">
                    <div className="flex justify-between items-center">
                      <div>
                        <h1 className="font-bold">Title</h1>
                        <p className="text-sm">Description</p>
                      </div>
                      <div>
                        Order now
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>

              <motion.div variants={BreathingVariants} transition={{ repeat: Infinity, repeatType: "mirror", duration: 1 }} initial="initial" animate="breathing1" className="absolute" style={{ margin: "0 23rem 10rem 0" }}>
                <div className="absolute w-24 h-24 text-center font-semibold text-white rounded-lg bg-primary cursor-pointer duration-300 hover:brightness-110">
                  <div className="loginLoading w-10 h-10" />
                </div>
              </motion.div>

              <motion.div variants={BreathingVariants} transition={{ repeat: Infinity, repeatType: "mirror", duration: 1 }} initial="initial" animate="breathing2" className="absolute" style={{ margin: "0 0 18rem 9rem" }}>
                <div className="absolute w-24 py-1 text-center font-semibold text-primary rounded-lg border border-primary cursor-pointer duration-300 hover:bg-primary hover:text-white">
                  Register
                </div>
                <div className="absolute mt-12 ml-6 w-24 py-1 text-center font-semibold text-white rounded-lg bg-primary cursor-pointer duration-300 hover:brightness-110" onClick={() => setLoginLoading(true)}>
                  {loginLoading ? <div className="loginLoading w-10 h-10" /> : "Login"}
                </div>
              </motion.div>

              <motion.div variants={BreathingVariants} transition={{ repeat: Infinity, repeatType: "mirror", duration: 1 }} initial="initial" animate="breathing1" className="absolute flex flex-col" style={{ marginLeft: "23rem" }}>
                <label className="text-sm mb-1 text-primary">Input</label>
                <div className="relative">
                  <input value={inputVal} className="pl-3 pr-8 py-1 rounded-lg border-2 border-primary/70" type="text" placeholder="your name" onChange={(e) => setInputVal(e.target.value)} />
                  {!inputVal.length || <X size={16} className="absolute top-3 right-3 cursor-pointer" onClick={() => setInputVal("")} />}
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full h-screen overflow-hidden interested">
        <div className="relative bg-black w-full h-full">
          <div className="text-white font-bold absolute z-10 w-full h-full flex flex-col gap-4 justify-center items-center">
            <h1 className="text-3xl text-center md:text-6xl">Oh, you're still here?<br />You might be interested!</h1>
            <Link href="/about" className="cursor-pointer border px-6 py-1 duration-500 ease-out rounded-lg md:text-2xl bg-glass hover:bg-white hover:text-black">Get to know me!</Link>
          </div>
          <div className=" overflow-hidden grid grid-cols-2 gap-6 brightness-50 saturate-50 md:grid-cols-3">
           <div className="scrolling-images grid grid-cols-1 gap-4">
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Skarisa.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Palmera.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Midas.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Skarisa.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Palmera.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Midas.png" />
           </div>

            <div className="scrolling-images-reversed grid grid-cols-1 gap-4">
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/BitX.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/James.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Bowtie.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/BitX.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/James.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Bowtie.png" />
            </div>

            <div className="scrolling-images grid grid-cols-1 gap-4">
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Pragmatic.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Allele.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/B2C_Moladin.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Pragmatic.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/Allele.png" />
              <motion.img className="h-[33vh] w-full object-cover rounded-lg" src="/img/projects/B2C_Moladin.png" />
            </div>
          </div>
        </div>
      </section>
      
    </main>
  )
}

export default Home;
