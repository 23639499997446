import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { X } from "react-feather";

const FeaturedProject = p => {
  const { mainImage, innerImage, timeStamp, projectName, children, engines = [], bgClass, handleToggle, selectedFeaturedProject, setSelectedFeaturedProject } = p;
  const [cardOpen, setCardOpen] = useState(selectedFeaturedProject === projectName);

  useEffect(() => {
    setCardOpen(selectedFeaturedProject === projectName);
  }, [selectedFeaturedProject]);

  return (
    <motion.div
      className={`card group relative w-full h-72 rounded-lg overflow-hidden ${bgClass} ${cardOpen ? "flex gap-5 p-10 shadow-inner" : "cursor-pointer"}`}
      onClick={() => {
        if (selectedFeaturedProject === projectName) return;
        setSelectedFeaturedProject(projectName);
      }}
    >
      
      <AnimatePresence mode="wait">
        {cardOpen && (
          <motion.div
            className="h-full absolute -bottom-32 -left-4 group-hover:brightness-30"
            animate={{ y: [-100, 0], opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <motion.img
              className="h-full z-0"
              src={innerImage}
              alt=""
            />
          </motion.div>
        )}
        {cardOpen || (
          <motion.img
            transition={{ ease: "easeIn", duration: 0.1 }}
            initial={{ x: -500, opacity: 1 }}
            animate={{ x: 0 }}
            exit={{ opacity: 0, x: -500 }}
            className="absolute top-0 left-0 rounded-lg duration-300 w-full h-full object-cover brightness-50 group-hover:scale-110 group-hover:brightness-100"
            src={mainImage}
            alt=""
          />
        )}
      </AnimatePresence>

      <motion.div className={`duration-300 text-white z-10 w-full ${cardOpen ? "" : "absolute bottom-3 left-3 group-hover:bottom-5"}`}>
        <motion.div layout="position">
          {cardOpen && <span className="montserrat font-light text-xs">{timeStamp}</span>}
          <div className="flex justify-between">
            <h1 className="text-4xl font-bold">{projectName}</h1>
            {cardOpen && <X className="cursor-pointer" onClick={() => setSelectedFeaturedProject("")} />}
          </div>
          <div className="flex gap-2 text-xs no-scrollbar">
            {engines.map((engine, index) => (<span key={index} className="border rounded-full py-px px-2">{engine}</span>))}
          </div>
        </motion.div>
        {cardOpen && (
          <motion.div className="mt-2" animate={{ opacity: [0, 1] }} transition={{ duration: 1, ease: "easeIn" }}>
            <div className="montserrat">{children}</div>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  )
};

export default FeaturedProject;
